<template>
  <div class="container">
    <Header></Header>
    <Sidebar class="sidebar"></Sidebar>
    <div class="grid-container">
      <div class="gird-item grid-item-file">
        <div class="tab">
          <div class="tab-header">{{$t('messages.pcellList')}}</div>
          <div class="tab-body" v-loading="listLoading">
            <div @contextmenu.prevent="onContextmenu($event, item)" class="item" v-for="item, key in pcellList"
              :key="key">
              <div v-if="!item.edit" :class="{active: item.selected, 'item-text': true}"
                @dblclick="selectPcellFile(item)">
                <span class="iconfont icon-Pell_Pcellwenjian"></span>{{item.name}}
              </div>
              <div class="item-text" v-else>
                <el-input :ref="key" v-model="item.name" size="mini" @change="changeName(item)"
                  @keyup.enter="changeName(item)" @blur="changeName(item)"></el-input>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="gird-item grid-item-args">
        <div class="tab">
          <div class="tab-header">{{$t('messages.ParameterSettings')}}</div>
          <div class="tab-body" v-if="args.length>0">
            <el-table class="el-table" :data="args" ref="paramsTable" header-cell-class-name="table_header"
              :show-header="false" height="100%">
              <el-table-column prop="arg_name" width="200">
                <template slot-scope="scope">
                  <el-input @change="changeParams" maxlength="10" show-word-limit size="mini"
                    v-model="scope.row.arg_name" placeholder="参数名称"></el-input>
                </template>
              </el-table-column>

              <el-table-column prop="var_name" width="200">
                <template slot-scope="scope">
                  <el-input @change="changeParams" maxlength="10" show-word-limit size="mini"
                    v-model="scope.row.var_name" placeholder="变量名"></el-input>
                </template>
              </el-table-column>

              <el-table-column prop="var_type" width="120">
                <template slot-scope="scope">
                  <el-select @change="changeParams" size="mini" v-model="scope.row.var_type" filterable
                    placeholder="请选择">
                    <el-option v-for="item in $t('messages.varOptions')" :key="item.value" :label="item.label"
                      :value="item.value"></el-option>
                  </el-select>
                </template>
              </el-table-column>

              <el-table-column prop="relation" width="200">
                <template slot-scope="scope">
                  <el-input @change="changeParams" size="mini" v-model="scope.row.relation"
                    placeholder="约束关系"></el-input>
                </template>
              </el-table-column>

              <el-table-column prop="value" width="200">
                <template slot-scope="scope">
                  <el-input @change="changeParams" size="mini" v-model="scope.row.value" placeholder="默认值"></el-input>
                </template>
              </el-table-column>

              <el-table-column prop="device" width="170">
                <template slot-scope="scope">
                  <el-select @change="changeParams" v-model="scope.row.arg_type" filterable
                    :placeholder="$t('messages.pleaseChoose')">
                    <el-option v-for="item in $t('messages.argOptions')" :key="item.value" :label="item.label"
                      :value="item.value"></el-option>
                  </el-select>
                </template>
              </el-table-column>

              <el-table-column prop="min" width="200">
                <template slot-scope="scope">
                  <el-input @change="changeParams" size="mini" v-model="scope.row.min" placeholder="最小值（选填）"></el-input>
                </template>
              </el-table-column>

              <el-table-column prop="max" width="200">
                <template slot-scope="scope">
                  <el-input @change="changeParams" size="mini" v-model="scope.row.max" placeholder="最大值（选填）"></el-input>
                </template>
              </el-table-column>

              <el-table-column label="操作" align="left" width="100">
                <template slot-scope="scope">
                  <span class="iconfont icon-tianjia1 args-add" @click="addParams(scope.$index+1)"></span>
                  <span class="iconfont icon-Pell_shanchu args-delete" @click="deleteParams(scope.$index)"></span>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <div class="tab-body" v-else>
            <div class="addParams" @click="addParams(0)" v-show="currentPcell">
              <span class="iconfont icon-Pell_tianjia"></span>
              {{$t('messages.addParams')}}
            </div>
          </div>
        </div>
      </div>
      <div class="gird-item grid-item-code">
        <div class="tab">
          <div class="tab-header-tab">
            <el-tabs v-model="activeTabName" type="border-card" closable @tab-remove="closeDialog"
              @tab-click="handleClickTab($event)">
              <el-tab-pane v-for="item of editableTabs" :key="item.name" :name="item.name">
                <span slot="label" style="font-size: 13px; line-height: 26px; display: flex">
                  <i class="iconfont icon-Pell_Pcellwenjian" style="padding-right: 6px; font-size: 18px"></i>
                  <span style="padding-right: 2px; font-size: 18px" v-if="!item.isSaved">*</span>
                  {{ item.name }}
                </span>
              </el-tab-pane>
            </el-tabs>
          </div>
          <div class="tab-body" id="codeDiv">
            <div v-show="currentPcell">
              <code-mirror ref="codes" v-model="code"></code-mirror>
            </div>
            <div v-show="!currentPcell"></div>
          </div>
        </div>
      </div>
      <div class="gird-item grid-item-image">
        <div class="tab">
          <div class="tab-header">{{$t('messages.preview')}}</div>
          <div class="tab-body" id="preview-tab-body">
            <canvas id="preview-image"></canvas>
          </div>
        </div>
      </div>
      <div class="gird-item grid-item-log">
        <div class="tab">
          <div class="tab-header">{{$t('messages.errorMessage')}}</div>
          <div class="tab-body" style="font-size:12px;padding:5px;">{{codeError}}</div>
        </div>
      </div>
    </div>
    <el-dialog :title="$t('messages.Warning')" :visible.sync="delPcellVisible" width="575px" :before-close="handleClose"
      :modal="false">
      <div class="dialog-body">
        <div style="display: flex">
          <i class="el-icon-warning-outline" style="color: #e15423; font-size: 20px; margin-right: 10px"></i>
          {{ $t('messages.deletePcellTips') }}
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="sure" size="mini" @click="submitDeletePcell">
          {{ $t('messages.sure') }}
        </el-button>
        <el-button type="cancel" @click="delPcellVisible = false" size="mini">{{ $t('messages.cancel') }}</el-button>
      </span>
    </el-dialog>
    <el-dialog :title="$t('messages.tips')" :visible.sync="closeTipsVisible" width="575px" :before-close="handleClose"
      :modal="false">
      <div class="dialog-body">
        <div style="display: flex">
          <i class="el-icon-warning-outline" style="color: #e15423; font-size: 20px; margin-right: 10px"></i>
          {{ $t('messages.saveChanges') }}
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="sure" size="mini" @click="handleCloseTab(true)">
          {{ $t('messages.sure') }}
        </el-button>
        <el-button type="danger" size="mini" @click="handleCloseTab(false)">
          {{ $t('messages.closeWithoutSaving') }}
        </el-button>
        <el-button type="cancel" @click="closeTipsVisible = false" size="mini">{{ $t('messages.cancel') }}</el-button>
      </span>
    </el-dialog>
    <el-dialog :modal="false" :title="$t('messages.SavetoLibrary')" :visible.sync="saveToLibraryDialogVisible"
      :close-on-click-modal="false" :before-close="saveToLibraryDialogClose" @open="saveStart">
      <el-form :model="saveToLibraryForm" :rules="rules" ref="saveToLibraryForm" class="demo-ruleForm">
        <div class="inputArea">
          <span class="inputInfo">{{ $t('messages.Group') }}:</span>
          <el-select v-model="value_group" filterable :placeholder="$t('messages.placeholder_0')">
            <el-option v-for="item in options_group" :key="item.value" :label="item.label"
              :value="item.value"></el-option>
          </el-select>
        </div>
        <div class="inputArea" v-if="value_group==0">
          <span class="inputInfo">{{ $t('messages.select') }}:</span>
          <el-select v-model="library_id" filterable :placeholder="$t('messages.placeholder_0')">
            <el-option v-for="item in libraryOptions" :key="item.value" :label="item.label"
              :value="item.value"></el-option>
          </el-select>
        </div>
        <div class="inputArea" v-if="value_group == 1">
          <span class="inputInfo">{{ $t('messages.teamSelected') }}:</span>
          <el-select v-model="value_teamGroup" filterable :placeholder="$t('messages.placeholder_0')">
            <el-option v-for="item in options_teamName" :key="item.value" :label="item.label"
              :value="item.value"></el-option>
          </el-select>
        </div>
        <div class="inputArea">
          <span class="inputInfo">{{ $t('messages.name') }}:</span>
          <el-form-item prop="form_deviceName">
            <el-input onkeyup="value=value.replace(/[^\w_-+&()[].~（）]/g,'')"
              v-model.trim="saveToLibraryForm.form_deviceName" :placeholder="$t('messages.placeholder_1')"
              :maxlength="50"></el-input>
          </el-form-item>
          <!-- <el-input v-model="saveToLibraryForm.form_deviceName" placeholder="请输入器件名称"></el-input> -->
        </div>

        <div class="labelSelect">
          <span class="inputInfo">{{ $t('messages.Label') }}:</span>
          <el-form-item prop="form_deviceLabel">
            <el-tag :key="tag" v-for="tag in saveToLibraryForm.form_deviceLabel" closable :disable-transitions="false"
              @close="handleCloseTag(tag)">
              {{tag}}
            </el-tag>
            <el-input class="input-new-tag" v-if="inputVisible" v-model="inputValue" ref="saveTagInput" size="small"
              @keyup.enter.native="handleInputConfirm" @blur="handleInputConfirm" maxlength="10"></el-input>
            <el-button type="sure" v-else class="button-new-tag" size="mini" @click="showInput">+ New Tag</el-button>
            <!-- <el-select size="mini" v-model="saveToLibraryForm.form_deviceLabel" multiple :multiple-limit="15" filterable allow-create default-first-option :placeholder="$t('messages.placeholder_0')">
              <el-option v-for="item in options_deviceCategory" :key="item.value" :label="item.label" :value="item.value"></el-option>
            </el-select> -->
          </el-form-item>
        </div>

        <div class="textAreaSelect">
          <span class="inputInfo">{{ $t('messages.NoteFigure') }}:</span>
          <el-form-item prop="form_deviceName">
            <el-upload class="upload-demo" ref="previewUpload" action="https://jsonplaceholder.typicode.com/posts/"
              :file-list="pcellFileList" :auto-upload="false" multiple
              accept=".png, .jpg, .JPG, .JPEG, .jpeg, .PNG .GIF, .gif" :limit="5">
              <el-button slot="trigger" size="small" type="primary">{{$t('messages.selectPcellFile')}}</el-button>
              <div slot="tip" class="el-upload__tip">{{$t('messages.uploadPreview')}}</div>
            </el-upload>
          </el-form-item>
          <!-- <el-input v-model="saveToLibraryForm.form_deviceName" placeholder="请输入器件名称"></el-input> -->
        </div>

        <div class="textAreaSelect">
          <span class="inputInfo">{{ $t('messages.note') }}:</span>
          <el-input type="textarea" v-model="value_notes" maxlength="128" :autosize="{ minRows: 3, maxRows: 5}"
            show-word-limit></el-input>
        </div>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="sure" @click="saveToLibrarySureButton(0)" size="mini">{{ $t('messages.sure') }}</el-button>
        <el-button type="cancel" @click="saveToLibraryCancelButton" size="mini">{{ $t('messages.cancel') }}</el-button>
      </span>
    </el-dialog>
    <el-dialog :title="$t('messages.pcellFileImport')" :visible.sync="fileImportVisible" width="575px"
      :before-close="handleClose" :modal="false">
      <div class="dialog-body">
        <div>
          <el-upload class="upload-demo" ref="upload" action="https://jsonplaceholder.typicode.com/posts/"
            :file-list="pcellFileList" :auto-upload="false" multiple accept=".json" :limit="5">
            <el-button slot="trigger" size="small" type="primary">{{$t('messages.selectPcellFile')}}</el-button>
            <el-button :loading="importFileLoading" style="margin-left: 10px;" size="small" type="success"
              @click="submitImport">{{$t('messages.upload')}}</el-button>
            <div slot="tip" class="el-upload__tip">{{$t('messages.uploadPcellFile')}}</div>
          </el-upload>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="cancel" @click="fileImportVisible = false" size="mini">{{ $t('messages.cancel') }}</el-button>
      </span>
    </el-dialog>
    <!-- <el-dialog :title="$t('messages.NoteFigure')" :visible.sync="previewImageVisible" width="575px" :before-close="handleClose" :modal="false">
      <div class="dialog-body">
        <div>
          
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="cancel" @click="cancelUploadPreview" size="mini">{{ $t('messages.cancel') }}</el-button>
      </span>
    </el-dialog> -->
    <el-dialog :title="$t('messages.tips')" :visible.sync="existsPcellVisible" width="575px" :before-close="handleClose"
      :modal="false">
      <div class="dialog-body">
        <div style="display: flex">
          <i class="el-icon-warning-outline" style="color: #e15423; font-size: 20px; margin-right: 10px"></i>
          {{ $t('messages.pcellExistsTips') }}
        </div>
      </div>
      <div style="display: inline-block; margin-left: 30px; margin-top:30px; vertical-align: top">
        <div class="item_class">
          {{existsPcellList.join('，')}}
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button :loading="replaceLoading" type="sure" size="mini" @click="submitReplaceFile">
          {{ $t('messages.yes') }}
        </el-button>
        <el-button :loading="replaceLoading" type="cancel" @click="cancelSubmitReplaceFile"
          size="mini">{{ $t('messages.no') }}</el-button>
      </span>
    </el-dialog>
    <el-dialog :title="$t('messages.tips')" :visible.sync="existsPcellNameVisible" width="575px"
      :before-close="handleClose" :modal="false">
      <div class="dialog-body">
        <div style="display: flex">
          <i class="el-icon-warning-outline" style="color: #e15423; font-size: 20px; margin-right: 10px"></i>
          {{ $t('messages.pcellExistsNameTips') }}
        </div>
      </div>
      <div style="display: inline-block; margin-left: 30px; margin-top:30px; vertical-align: top">
        <div class="item_class">
          {{existsPcellList.join('，')}}
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button :loading="replaceLoading" type="sure" size="mini" @click="saveToLibrarySureButton(1)">
          {{ $t('messages.yes') }}
        </el-button>
        <el-button :loading="replaceLoading" type="cancel" @click="cancelSubmitReplacePcellName"
          size="mini">{{ $t('messages.no') }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import Md5 from "js-md5";
import CodeMirror from "@/components/common/CodeEditor.vue";
import { saveAs } from "file-saver";
import bus from "@/components/common/bus";
import Header from "./Header.vue";
import Sidebar from "./Sidebar.vue";
import {
  createPcell,
  editPcell,
  deletePcell,
  getPcellList,
  importPcell,
  getLibraryList,
  savePcellJs,
} from "@/api/api";
import {
  getTeamList_api,
  createMyComponent_api,
  createTeamComponent_api,
} from "@/api/file/file.js";
import { saveJSON, downloadBlob, deepClone } from "@/utils/utils";
import { deepCopy } from "../../utils/utils";
import { fileKernelCell2Gdstk } from "@/components/homes/fileList/function/fileListPublicFn";
import _ from "lodash";
import { file } from "jszip";
const AutoToolCanvasComponent = require("../../utils/tools/canvas_component.js");
var canvas2D = new AutoToolCanvasComponent(); //NOSONAR
export default {
  components: {
    Sidebar,
    Header,
    CodeMirror,
  },
  data() {
    var projectNameRule = (rule, value, callback) => {
      if (value === "") {
        callback();
      } else if (
        [...value][0] == " " ||
        [...value][[...value].length - 1] == " "
      ) {
        callback(new Error(this.$t("messages.fileTip1")));
      } else if (!/^[\s&()~+-_\[\]0-9a-zA-Z\u4e00-\u9fa5]+$/.test(value)) {
        callback(new Error(this.$t("messages.fileTip1")));
      } else {
        callback();
      }
    };
    var fileNameRule = (rule, value, callback) => {
      if (value === "") {
        callback();
      } else if (
        [...value][0] == " " ||
        [...value][[...value].length - 1] == " "
      ) {
        callback(this.$t("messages.fileTip2"));
      } else if (!/^[\s&()~+-._\[\]0-9a-zA-Z]+$/.test(value)) {
        callback(this.$t("messages.fileTip2"));
      } else {
        callback();
      }
    };
    var labelNameRule = (rule, value, callback) => {
      if (value.length == 0) {
        callback(new Error(this.$t("messages.fileTip4")));
      } else {
        var show = true;
        for (let i in value) {
          if (value[i].name == undefined) {
            var tempValue = value[i];
          } else {
            var tempValue = value[i].name;
          }

          var len = 0;
          for (let j = 0; j < tempValue.length; j++) {
            // var c = tempValue.charCodeAt(j);
            // //单字节加1
            // if ((c >= 0x0001 && c <= 0x007e) || (0xff60 <= c && c <= 0xff9f)) {
            //   len++;
            // } else {
            //   len += 2;
            // }
            len++;
          }

          if (len > 10) {
            show = false;
          }
        }
        if (!show) {
          callback(new Error(this.$t("messages.fileTip3")));
        } else {
          callback();
        }
      }
    };
    return {
      clickRoute: null,
      targetName: null,
      delPcellVisible: false,
      closeTipsVisible: false,
      saveToLibraryDialogVisible: false,
      existsPcellVisible: false,
      existsPcellNameVisible: false,
      fileImportVisible: false,
      importFileLoading: false,
      previewImageVisible: false,
      uploadPreviewLoading: false,
      code: "",
      selectedText: "",
      dialogVisible: false,
      lastIndex: 0,
      args: [],
      pcellList: [],
      editableTabs: [],
      activeTabName: "",
      currentPcell: null,
      currentIndex: -2,
      lineNum: 0,
      listLoading: false,
      delPcell: null,
      oldName: "",
      codeError: "",
      saveToLibraryForm: {
        form_deviceName: "",
        form_deviceLabel: [],
      },
      mark_img: [],
      //输入校验
      rules: {
        form_projectName: [{ validator: projectNameRule, trigger: "blur" }],
        form_importProjectName: [
          { validator: projectNameRule, trigger: "blur" },
        ],
        form_fileName: [{ validator: fileNameRule, trigger: "blur" }],
        form_cellName: [{ validator: fileNameRule, trigger: "blur" }],
        form_schematicName: [{ validator: fileNameRule, trigger: "blur" }],
        form_copyName: [{ validator: fileNameRule, trigger: "blur" }],
        form_deviceName: [{ validator: fileNameRule, trigger: "blur" }],
      },
      value_group: 0,
      options_group: [
        {
          // 我的器件库
          label: this.$t("messages.MyLibrary"),
          value: 0,
        },
        {
          // 团队器件库
          label: this.$t("messages.TeamLibrary"),
          value: 1,
        },
      ],
      value_teamGroup: "",
      options_teamName: [],
      value_notes: "",
      library_id: "",
      inputVisible: false,
      inputValue: "",
      libraryOptions: [],
      pcellFileList: [],
      existsPcellList: [],
      replaceLoading: false,
      saveToLibraryPcell: null,
      selectedText: "",
      initStatus: true,
    };
  },
  created() {},
  methods: {
    handleClose(done) {
      done();
    },
    handleCloseTag(tag) {
      this.saveToLibraryForm.form_deviceLabel.splice(
        this.saveToLibraryForm.form_deviceLabel.indexOf(tag),
        1
      );
    },
    //弹出弹窗
    closeDialog(targetName) {
      if (this.currentPcell.isSaved) {
        this.removeTab(targetName);
        return;
      }
      this.closeTipsVisible = true;
      this.targetName = targetName;
    },
    selectPcellItem(targetName) {
      let index = this.pcellList.findIndex((item) => item.name == targetName);
      for (let i = 0; i < this.pcellList.length; i++) {
        this.pcellList[i].selected = false;
      }
      this.pcellList[index].selected = true;
    },
    removeTab(targetName) {
      let deductIndex = this.editableTabs.findIndex(
        (item) => item.name == targetName
      );
      sessionStorage.removeItem(this.editableTabs[deductIndex].snow_id);
      this.editableTabs.splice(deductIndex, 1);
      if (this.activeTabName == targetName) {
        this.currentPcell =
          this.editableTabs[deductIndex - 1 > -1 ? deductIndex - 1 : 0];
        this.activeTabName = this.currentPcell.name;
      }
      this.currentIndex = this.pcellList.findIndex(
        (item) => item.snow_id == this.currentPcell.snow_id
      );
      this.selectPcellItem(this.currentPcell.name);
    },
    handleCloseTab(Save) {
      if (Save) {
        this.savePcell();
        this.removeTab(this.targetName);
      } else {
        this.removeTab(this.targetName);
      }
      this.closeTipsVisible = false;
    },
    //tab栏单击事件
    handleClickTab(route) {
      this.initStatus = true;
      this.clickRoute = route;
      if (route !== this.$route.fullPath) {
        this.clickTab(this.clickRoute);
      }
      this.selectPcellItem(route.name);
      setTimeout(() => {
        this.initStatus = false;
      }, 1000);
    },
    clickTab(route) {
      let targetName = route.name;
      this.currentPcell = this.pcellList.filter(
        (item) => item.name == targetName
      )[0];
      let all_params = sessionStorage.getItem(this.currentPcell.snow_id);
      all_params = JSON.parse(all_params);
      try {
        this.args = Object.values(all_params.params);
      } catch (e) {
        this.args = [];
      }
      let editor = this.$refs.codes.codeMirror;
      this.code = all_params.pcell;
      editor.setValue(this.code);
    },
    async onContextmenu(event, item) {
      this.$contextmenu({
        items: [
          {
            label: this.$t("messages.create"),
            onClick: () => {
              this.createNewPcellFile();
            },
          },
          {
            label: this.$t("messages.rename"),
            onClick: () => {
              item.edit = true;
              this.oldName = deepCopy(item.name);
            },
          },
          {
            label: this.$t("messages.delete"),
            onClick: () => {
              this.delPcell = item;
              this.delPcellVisible = true;
            },
          },
          {
            label: this.$t("messages.SavetoLibrary"),
            onClick: () => {
              this.saveToLibraryPcell = item;
              this.saveToLibraryDialogVisible = true;
            },
          },
        ],
        event, // 鼠标事件信息
        customClass: "custom-class", // 自定义菜单 class
        zIndex: 1000, // 菜单样式 z-index
        minWidth: 170, // 主菜单最小宽度
      });
    },
    handleTableAdd() {},
    handleTableDelete() {},
    changeParams() {
      if (this.initStatus) return;
      this.currentPcell.isSaved = false;
      let params_json = {};
      for (let i = 0; i < this.args.length; i++) {
        let params_value = {
          arg_name: this.args[i].arg_name,
          arg_type: this.args[i].arg_type,
          var_name: this.args[i].var_name,
          var_type: this.args[i].var_type,
          value: this.args[i].value,
          relation: this.args[i].relation,
          min: this.args[i].min,
          max: this.args[i].max,
        };
        params_json[this.args[i].var_name] = params_value;
      }
      let save_obj = {
        params: params_json,
        pcell: this.code,
      };
      sessionStorage.setItem(
        this.currentPcell.snow_id,
        JSON.stringify(save_obj)
      );
    },
    async getObjectData(fileUrl) {
      let apiKey = localStorage.getItem("apiKey");
      let minio_name = localStorage.getItem("minio_name");
      let bucket_name = localStorage.getItem("bucket_name");
      let time = parseInt(new Date().getTime() / 1000);
      let file_content = await fetch("/file/api/v1/get_object_data/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          username: minio_name,
          sign: Md5(`${apiKey}_${time}`),
        },
        body: JSON.stringify({
          time,
          bucket_name,
          object_path: fileUrl,
        }),
      });
      let array_buffer = await file_content.arrayBuffer();
      let u8 = new Uint8Array(array_buffer);
      let enc = new TextDecoder("utf-8");
      return JSON.parse(enc.decode(u8));
    },
    lineTo() {
      let codeMirrorSon = this.$refs.codes.codeMirror;
      codeMirrorSon.scrollTo(
        null,
        codeMirrorSon.getScrollInfo().top +
          codeMirrorSon.defaultTextHeight() *
            (this.lineNum - codeMirrorSon.getScrollInfo().topline - 1)
      );
      codeMirrorSon.setCursor({ line: this.lineNum - 1, ch: 0 });
      codeMirrorSon.focus();
    },
    async getPcellListData() {
      this.listLoading = true;
      let res = await getPcellList();
      if (res.code == 200000) {
        this.pcellList = res.data.map((item) => {
          let obj = {
            edit: false,
            selected: false,
            isSaved: true,
          };
          return Object.assign(item, obj);
        });
        if (this.currentIndex > -1) {
          this.currentPcell = this.pcellList[this.currentIndex];
          this.pcellList[this.currentIndex].selected = true;
          let old_eaitTabs = deepClone(this.editableTabs);
          for (let i = 0; i < old_eaitTabs.length; i++) {
            for (let j = 0; j < this.pcellList.length; j++) {
              if (old_eaitTabs[i].snow_id == this.pcellList[j].snow_id) {
                this.editableTabs[i] = this.pcellList[j];
              }
            }
          }
        }
        this.listLoading = false;
      }
      this.listLoading = false;
    },
    async changeName(row) {
      if (row.name == this.oldName) {
        row.edit = false;
        return;
      }
      if (row.name.length == 0) {
        row.name = this.oldName;
        return;
      }
      row.edit = false;
      let params = new FormData();
      params.append("name", row.name);
      params.append("file", "");
      params.append("snow_id", row.snow_id);
      let res = await editPcell(params);
      if (res.code == 200000) {
        // this.$message.success(this.$t("messages.operationSuccess"));
        this.listLoading = true;
        setTimeout(() => {
          this.getPcellListData();
        }, 500);
      }
    },
    async selectPcellFile(row) {
      this.initStatus = true;
      let res_file = {};
      for (let i = 0; i < this.pcellList.length; i++) {
        this.pcellList[i].edit = false;
        this.pcellList[i].selected = false;
        if (this.pcellList[i].snow_id == row.snow_id) {
          this.currentIndex = i;
        }
      }
      row.selected = true;
      this.currentPcell = row;
      this.activeTabName = row.name;
      if (!_.find(this.editableTabs, row)) {
        this.editableTabs.push(row);
      }
      if (sessionStorage.getItem(row.snow_id)) {
        res_file = JSON.parse(sessionStorage.getItem(row.snow_id));
      } else {
        res_file = await this.getObjectData(this.currentPcell.url);
      }
      sessionStorage.setItem(
        this.currentPcell.snow_id,
        JSON.stringify(res_file)
      );
      try {
        this.args = Object.values(res_file.params);
      } catch (e) {
        this.args = [];
      }
      let editor = this.$refs.codes.codeMirror;
      this.code = res_file.pcell;
      editor.setValue(this.code);
      setTimeout(() => {
        this.initStatus = false;
      }, 1000);
    },
    axiosCreateNewPcell: _.debounce(async function (params) {
      let res = await createPcell(params);
      if (res.code == 200000) {
        this.lastIndex += 1;
        setTimeout(() => {
          this.getPcellListData();
        }, 500);
        setTimeout(() => {
          this.pcellList[this.pcellList.length - 1].edit = true;
          this.oldName = deepCopy(
            this.pcellList[this.pcellList.length - 1].name
          );
        }, 1000);
      }
    }),
    getLastIndex() {
      if (this.pcellList.length > 0) {
        if (this.pcellList[this.pcellList.length - 1].name.split("Pcell-")[1]) {
          let index_arr = [0];
          for (let i = 0; i < this.pcellList.length; i++) {
            index_arr.push(
              Number(
                this.pcellList[this.pcellList.length - 1].name.split(
                  "Pcell-"
                )[1]
              )
            );
          }
          index_arr = index_arr.sort((left, right) => right - left);
          return index_arr[0];
        }
      } else {
        return 0;
      }
    },
    async createNewPcellFile() {
      let params = new FormData();
      this.lastIndex = this.getLastIndex();
      let params_obj = {
        params: {},
        pcell: "",
      };
      let json_blob = saveJSON(params_obj, `Pcell-${this.lastIndex + 1}`);
      params.append("name", `Pcell-${this.lastIndex + 1}`);
      params.append("file", json_blob);
      params.append("is_cover", 0);
      this.axiosCreateNewPcell(params);
    },
    axiosDeletePcell: _.debounce(async function (params) {
      let res = await deletePcell(params);
      if (res.code == 200000) {
        this.$message.success(this.$t("messages.deleteSuccess"));
        this.listLoading = true;
        if (
          this.editableTabs.filter((item) => item.name == this.delPcell.name)
            .length > 0
        ) {
          this.removeTab(this.delPcell.name);
        }
        setTimeout(() => {
          this.getPcellListData();
        }, 500);
        this.delPcellVisible = false;
      }
    }),
    async submitDeletePcell() {
      let params = {
        snow_ids: [this.delPcell.snow_id],
      };
      this.axiosDeletePcell(params);
    },
    addParams(index) {
      let new_obj = {
        arg_name: "",
        arg_type: 0,
        var_name: "",
        var_type: 0,
        value: "",
        relation: "",
        min: "",
        max: "",
      };
      this.args.splice(index, 0, new_obj);
      this.currentPcell.isSaved = false;
    },
    deleteParams(index) {
      this.args.splice(index, 1);
      this.currentPcell.isSaved = false;
    },
    async savePcell() {
      // 分两个部分，参数和代码，代码为json
      // if (this.args.length == 0) {
      //   this.$message.error(this.$t('messages.noParams'))
      //   return;
      // }
      let params_json = {};
      for (let i = 0; i < this.args.length; i++) {
        let params_value = {
          arg_name: this.args[i].arg_name,
          arg_type: this.args[i].arg_type,
          var_name: this.args[i].var_name,
          var_type: this.args[i].var_type,
          value: this.args[i].value,
          relation: this.args[i].relation,
          min: this.args[i].min,
          max: this.args[i].max,
        };
        params_json[this.args[i].var_name] = params_value;
      }
      let save_obj = {
        params: params_json,
        pcell: this.code,
      };
      let params = new FormData();
      let json_blob = saveJSON(save_obj, this.currentPcell.name);
      // params.append("name", this.currentPcell.name);
      params.append("file", json_blob);
      params.append("snow_id", this.currentPcell.snow_id);
      params.append("is_cover", 0);
      let res = await editPcell(params);
      if (res.code == 200000) {
        this.$message.success(this.$t("messages.saveSuccess"));
        this.currentPcell.isSaved = true;
        sessionStorage.setItem(
          this.currentPcell.snow_id,
          JSON.stringify(save_obj)
        );
      }
    },
    runPcell() {
      let pcell_json = {};
      try {
        pcell_json = JSON.parse(this.code);
      } catch (e) {
        this.codeError = e;
        return;
      }
      if (this.args.length == 0) {
        this.codeError = this.$t("messages.noParamsError");
        return;
      }
      let pcell_obj = new Kernel.PCell();
      let params_json = {};
      for (let i = 0; i < this.args.length; i++) {
        let params_value = {
          // arg_name: this.args[i].arg_name,
          // arg_type: this.args[i].arg_type,
          // var_name: this.args[i].var_name,
          // var_type: this.args[i].var_type,
          comment: "",
          value: this.args[i].value,
          // relation: this.args[i].relation,
          min: this.args[i].min,
          max: this.args[i].max,
        };
        params_json[this.args[i].var_name] = params_value;
      }
      let code_obj = JSON.parse(this.code);
      let params_obj = params_json;
      pcell_obj.set_commands(code_obj);
      pcell_obj.set_param(params_obj);
      let cell = pcell_obj.generate();
      let file = fileKernelCell2Gdstk(cell);
      this.codeError = "编译成功";
      bus.$emit("screen_lock", false);

      canvas2D.render(
        document.getElementById("preview-image"),
        file,
        file.name
      );
    },
    loadScript(src, callback) {
      var script = document.createElement("script"),
        head = document.getElementsByTagName("head")[0];
      script.type = "text/javascript";
      script.charset = "UTF-8";
      script.src = src;
      if (script.addEventListener) {
        script.addEventListener(
          "load",
          function () {
            callback();
          },
          false
        );
      } else if (script.attachEvent) {
        script.attachEvent("onreadystatechange", function () {
          var target = window.event.srcElement;
          if (target.readyState == "loaded") {
            callback();
          }
        });
      }
      head.appendChild(script);
    },
    removejscssfile(filename, filetype) {
      let targetelement =
        filetype == "js" ? "script" : filetype == "css" ? "link" : "none";
      let targetattr =
        filetype == "js" ? "src" : filetype == "css" ? "href" : "none";
      let allsuspects = document.getElementsByTagName(targetelement);
      for (var i = allsuspects.length; i >= 0; i--) {
        if (
          allsuspects[i] &&
          allsuspects[i].getAttribute(targetattr) != null &&
          allsuspects[i].getAttribute(targetattr).indexOf(filename) != -1
        )
          allsuspects[i].parentNode.removeChild(allsuspects[i]);
      }
    },
    testCode() {
      var that = this;
      this.loadScript("http://127.0.0.1:5500/test.js", function () {
        setTimeout(() => {
          let file_lib = new Kernel.Library("CPW");
          file_lib.read_gds("CPW");
          let cell = file_lib.cells[0];
          let file = fileKernelCell2Gdstk(cell);
          that.codeError = "编译成功";
          bus.$emit("screen_lock", false);
          canvas2D.render(
            document.getElementById("preview-image"),
            file,
            file.name
          );
        }, 1000);
      });
      setTimeout(() => {
        this.removejscssfile("http://127.0.0.1:5500/test.js", "js");
      }, 500);
    },
    //存到器件库弹窗
    async saveToLibrarySureButton(cover) {
      if (this.value_group == 0) {
        // 个人
        if (
          !this.library_id ||
          !this.saveToLibraryForm.form_deviceName ||
          this.saveToLibraryForm.form_deviceLabel.length == 0
        ) {
          this.$message.error(this.$t("messages.paramsIncomplate"));
          return;
        }
      } else if (this.value_group == 1) {
        if (
          !this.value_teamGroup ||
          !this.saveToLibraryForm.form_deviceName ||
          this.saveToLibraryForm.form_deviceLabel.length == 0
        ) {
          this.$message.error(this.$t("messages.paramsIncomplate"));
          return;
        }
      }
      let res_file = await this.getObjectData(this.saveToLibraryPcell.url);
      let pcell_json = {};
      try {
        pcell_json = JSON.parse(res_file.pcell);
      } catch (e) {
        this.$message.error(e);
        return;
      }
      if (Object.keys(res_file.params).length == 0) {
        this.$message.error(this.$t("messages.noParamsError"));
        return;
      }
      let pcell_obj = new Kernel.PCell();
      let args = Object.keys(res_file.params);
      let params_obj = {};
      for (let i = 0; i < args.length; i++) {
        params_obj[args[i]] = {
          max: res_file.params[args[i]].max,
          min: res_file.params[args[i]].min,
          comment: "",
          value: res_file.params[args[i]].value,
        };
      }
      pcell_obj.set_commands(pcell_json);
      pcell_obj.set_param(params_obj);
      let cell = pcell_obj.generate();
      cell.name = this.saveToLibraryForm.form_deviceName;
      let lib = new Kernel.Library(this.saveToLibraryForm.form_deviceName);
      lib.add_cell(cell);
      // 转换gds数据
      let lib_binaryData = lib.to_gds();
      let size = lib_binaryData.size();
      let binaryArray = [];
      for (let i = 0; i < size; i++) {
        binaryArray.push(lib_binaryData.get(i));
      }
      let data_u8 = new Uint8Array(binaryArray);
      let formData = new FormData();
      let image_files = this.$refs.previewUpload.uploadFiles;
      for (let i = 0; i < image_files.length; i++) {
        formData.append("mark_img", image_files[i].raw);
      }
      if (this.value_group == 0) {
        formData.append("name", this.saveToLibraryForm.form_deviceName);
        formData.append("unit", lib.unit);
        formData.append("precision", lib.precision);
        formData.append("label_list", this.saveToLibraryForm.form_deviceLabel);
        formData.append("notes", this.value_notes);
        formData.append("content", new Blob([data_u8]));
        formData.append("is_cover", cover);
        formData.append("cell_component_type", 2);
        formData.append("library_snow_id", this.library_id);
        formData.append("render_attrs", params_obj);
        let res = await createMyComponent_api(formData);
        if (res.code == 200000) {
          if (res.data.name_exists && res.data.name_exists.length > 0) {
            this.existsPcellNameVisible = true;
          } else {
            this.$message.success(this.$t("messages.saveSuccess"));
            this.cancelSubmitReplacePcellName();
          }
        }
      } else if (this.value_group == 1) {
        formData.append("name", this.saveToLibraryForm.form_deviceName);
        formData.append("unit", lib.unit);
        formData.append("precision", lib.precision);
        formData.append("label_list", this.saveToLibraryForm.form_deviceLabel);
        formData.append("notes", this.value_notes);
        formData.append("content", new Blob([data_u8]));
        formData.append("is_cover", cover);
        formData.append("cell_component_type", 2);
        formData.append("team_id", this.value_teamGroup);
        formData.append("render_attrs", params_obj);
        let res = await createTeamComponent_api(formData);
        if (res.code == 200000) {
          if (res.data.name_exists && res.data.name_exists.length > 0) {
            this.existsPcellVisible = true;
          } else {
            this.$message.success(this.$t("messages.saveSuccess"));
            this.cancelSubmitReplacePcellName();
          }
        }
      }
    },
    //存到器件库初始化
    resetSaveToLibrary() {
      this.library_id = "";
      this.value_group = 0;
      this.value_teamGroup = "";
      this.value_notes = "";
      this.$refs.saveToLibraryForm.resetFields();
    },
    //存到器件库取消按钮
    saveToLibraryCancelButton() {
      this.saveToLibraryDialogVisible = false;
      this.resetSaveToLibrary();
    },

    saveToLibraryDialogClose() {
      this.saveToLibraryDialogVisible = false;
      this.resetSaveToLibrary();
    },
    showInput() {
      this.inputVisible = true;
      this.$nextTick((_) => {
        this.$refs.saveTagInput.$refs.input.focus();
      });
    },

    handleInputConfirm() {
      let inputValue = this.inputValue;
      if (inputValue) {
        let find_index = this.saveToLibraryForm.form_deviceLabel.filter(
          (item) => item == inputValue
        );

        if (find_index.length > 0) {
          this.$message.error(this.$t("messages.labelExists"));
          return;
        }
        this.saveToLibraryForm.form_deviceLabel.push(inputValue);
      }
      this.inputVisible = false;
      this.inputValue = "";
    },
    async saveStart() {
      let res = await getLibraryList();
      if (res.code == 200000) {
        this.libraryOptions = res.data.data.map((item) => {
          const ob = { label: item.name, value: item.snow_id };
          return Object.assign(item, ob);
        });
      }
      let team_res = await getTeamList_api({
        start: 0,
        rows: 50,
        key_word: "",
      });
      if (team_res.code == 200000) {
        let team_objs = team_res.data.data;
        this.options_teamName = team_objs.map((item) => {
          return {
            value: item.snow_team_id,
            label: item.team_name,
            gns: item.gns,
          };
        });
      }
    },
    async submitImport() {
      this.importFileLoading = true;
      let files = this.$refs.upload.uploadFiles;

      if (files.length == 0) return;
      let params = new FormData();
      for (let i = 0; i < files.length; i++) {
        params.append("file", files[i].raw);
      }

      params.append("is_cover", 0);
      let res = await importPcell(params);
      if (res.code == 200000) {
        if (res.data.name_exists.length > 0) {
          this.existsPcellList = res.data.name_exists;
          this.existsPcellVisible = true;
        } else {
          this.$message.success(this.$t("messages.importSucceeded"));
          this.$refs.upload.clearFiles();
          setTimeout(() => {
            this.getPcellListData();
          }, 500);
        }
        this.fileImportVisible = false;
        this.importFileLoading = false;
      }
    },
    async submitReplaceFile() {
      let existsFiles = [];
      for (let i = 0; i < this.existsPcellList.length; i++) {
        existsFiles.push(
          this.$refs.upload.uploadFiles.filter(
            (item) => item.name.split(".json")[0] == this.existsPcellList[i]
          )[0]
        );
      }

      // this.$refs.upload.clearFiles();
      let params = new FormData();
      for (let i = 0; i < existsFiles.length; i++) {
        params.append("file", existsFiles[i].raw);
      }

      params.append("is_cover", 1);
      let res = await importPcell(params);
      if (res.code == 200000) {
        this.$message.success(this.$t("messages.importSucceeded"));
        this.$refs.upload.clearFiles();
        this.existsPcellList = [];
        this.existsPcellVisible = false;
      }
    },
    cancelSubmitReplaceFile() {
      this.$refs.upload.clearFiles();
      this.existsPcellList = [];
      this.existsPcellVisible = false;
    },
    cancelSubmitReplacePcellName() {
      this.$refs.previewUpload.clearFiles();
      this.existsPcellNameVisible = false;
      this.saveToLibraryCancelButton();
    },
    async saveAllPCellFile() {
      let waitSavePCells = this.editableTabs.filter(
        (item) => item.isSaved == false
      );
      bus.$emit("screen_lock", true);
      for (let i = 0; i < waitSavePCells.length; i++) {
        let pcell_obj = sessionStorage.getItem(waitSavePCells[i].snow_id);
        let pcell_blob = saveJSON(
          JSON.parse(pcell_obj),
          waitSavePCells[i].name
        );
        let params = new FormData();
        // params.append("name", waitSavePCells[i].name);
        params.append("file", pcell_blob);
        params.append("snow_id", waitSavePCells[i].snow_id);
        let res = await editPcell(params);
        if (res.code == 200000) {
          waitSavePCells[i].isSaved = true;
        }
      }
      this.$message.success(this.$t("messages.saveSuccess"));
      bus.$emit("screen_lock", false);
    },
    async pasteCode() {
      try {
        let clipboardText;
        if (!this.selectedText) {
          clipboardText = await navigator.clipboard.readText();
        } else {
          clipboardText = this.selectedText;
        }
        const editor = this.$refs.codes.codeMirror;
        const cursor = editor.getCursor(); // 获取当前光标位置
        const pos = {
          // 构造插入位置对象
          line: cursor.line + 1, // 在当前行的下一行插入
          ch: 0, // 在行首插入
        };
        editor.getDoc().replaceRange(clipboardText, pos); // 插入文本
      } catch (err) {
        console.error("Failed to read clipboard contents: ", err);
      }
    },
    getSelect() {
      this.selectedText = this.$refs.codes.codeMirror.getSelection();
    },
  },

  mounted() {
    bus.$on("PcellGenerate", (data) => {
      this.dialogVisible = true;
    });
    this.getPcellListData();
    bus.$on("pcll-save", (data) => {
      this.savePcell();
    });
    bus.$on("pcll-saveAll", (data) => {
      this.saveAllPCellFile();
    });
    bus.$on("pcll-create", (data) => {
      this.createNewPcellFile();
    });
    bus.$on("pcll-import", (data) => {
      this.fileImportVisible = true;
    });
    bus.$on("pcll-export", (data) => {
      let params_json = {};
      for (let i = 0; i < this.args.length; i++) {
        let params_value = {
          arg_name: this.args[i].arg_name,
          arg_type: this.args[i].arg_type,
          var_name: this.args[i].var_name,
          var_type: this.args[i].var_type,
          value: this.args[i].value,
          relation: this.args[i].relation,
          min: this.args[i].min,
          max: this.args[i].max,
        };
        params_json[this.args[i].var_name] = params_value;
      }
      let save_obj = {
        params: params_json,
        pcell: this.code,
      };
      let json_blob = saveJSON(save_obj, this.currentPcell.name);
      downloadBlob(json_blob, `${this.currentPcell.name}.json`);
    });
    // bus.$on('pcll-cut', data => {
    //   document.execCommand('cut');
    // });
    // bus.$on('pcll-copy', data => {
    //   this.selectedText = this.$refs.codes.codeMirror.getSelection();
    //   document.execCommand('Copy');
    // });
    // bus.$on('pcll-paste', data => {
    //   // 判断浏览器是否支持粘贴操作
    //   this.pasteCode();
    // });
    // bus.$on('pcll-delete', data => {
    //   this.getSelect();
    //   const cursor = this.$refs.codes.codeMirror.getCursor();

    //   this.lineNum = cursor.line + 1;
    //   this.code = this.code.replace(this.selectedText, '')
    //   this.$refs.codes.codeMirror.setValue(this.code);
    //   this.lineTo();
    // });
    bus.$on("pcll-selectAll", (data) => {
      this.$refs.codes.codeMirror.execCommand("selectAll");
    });
    bus.$on("pcll-undo", (data) => {
      this.$refs.codes.codeMirror.undo();
    });
    bus.$on("pcll-redo", (data) => {
      this.$refs.codes.codeMirror.redo();
    });
    bus.$on("pcll-run", (data) => {
      if (
        !this.activeTabName ||
        this.editableTabs.length == 0 ||
        !this.currentPcell
      )
        return;
      if (!this.currentPcell.isSaved) {
        this.savePcell();
      }
      bus.$emit("screen_lock", true);
      setTimeout(() => {
        this.runPcell();
        // this.testCode();
      }, 1000);
    });
    let editor = this.$refs.codes.codeMirror;
    editor.setSize(
      "auto",
      window.getComputedStyle(document.getElementById("codeDiv")).height
    );
    let canvas2D = document.getElementById("preview-image");
    const width = window.getComputedStyle(
      document.getElementById("preview-tab-body")
    ).width;
    const height = window.getComputedStyle(
      document.getElementById("preview-tab-body")
    ).width;
    canvas2D.style.width = width;
    canvas2D.style.height = width;
    canvas2D.setAttribute("width", width);
    canvas2D.setAttribute("height", width);
  },
  watch: {
    code: {
      handler(newVal, oldVal) {
        if (oldVal) {
          if (oldVal !== newVal) {
            this.changeParams();
          }
        }
      },
    },
  },
};
</script>
<style lang="scss">
</style>
<style lang="less" scoped>
// * {
//   color: #333333;
// }
/deep/ .el-table__body-wrapper {
  height: 140px !important;
}
.container {
  background: #ffffff;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.sidebar {
  width: 100%;
}

.grid-container {
  height: 100%;
  width: 100%;
  margin: 0px 0px 10px 0px;
  display: grid;
  grid-gap: 10px;
  // grid-template-rows: 206px 586px 216px;
  // grid-template-columns: 221px 1315px 376px;
  grid-template-rows: 1fr 3fr 1fr;
  grid-template-columns: 1fr 4fr 1fr;
  grid-auto-flow: row dense;
  .grid-item {
    background-color: #ddd;
    text-align: center;
  }
  .grid-item-file {
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 1;
    grid-row-end: 4;
  }
  .grid-item-args {
    grid-row-start: 1;
    grid-row-end: 2;
    grid-column-start: 2;
    grid-column-end: 4;
  }
  .grid-item-code {
    grid-row-start: 2;
    grid-row-end: 3;
    grid-column-start: 2;
    grid-column-end: 3;
  }
  .grid-item-image {
    grid-row-start: 2;
    grid-row-end: 3;
    grid-column-start: 3;
    grid-column-end: 4;
  }
  .grid-item-log {
    grid-row-start: 3;
    grid-row-end: 4;
    grid-column-start: 2;
    grid-column-end: 4;
  }
}

.tab {
  display: inline-block;
  background: #f4f4f4;
  width: 100%;
  height: 100%;
  border: 1px solid #dddddd;
  .tab-header {
    height: 26px;
    line-height: 26px;
    padding-left: 10px;
    width: calc(100% - 10px);
    font-size: 13px;
    color: #333333;
    font-weight: bold;
    display: flex;
    border-bottom: 1px solid #dddddd;
    position: relative;
  }

  .tab-body {
    background: #ffffff;
    width: 100%;
    height: calc(100% - 31px);
    max-height: calc(100% - 31px);
    display: flex;
    flex-direction: column;
    display: -webkit-flex;
    box-sizing: border-box;
    overflow-y: auto;
    .addParams {
      cursor: pointer;
      color: #0c7de6;
      font-size: 13px;
    }
    .item {
      // padding: 5px;
      font-size: 14px;
      color: #333333;
    }
    .item:hover {
      background: #dce6f8;
      cursor: pointer;
    }
    .iconfont {
      font-size: 20px !important;
      vertical-align: middle;
      color: #0c7de6;
    }
    .item-text {
      padding: 5px;
    }
    .active {
      background: #dce6f8;
      border-right: solid 4px #0c7de6;
    }
    /deep/ .el-input__inner {
      background: transparent !important;
    }
    .el-select {
      // width: 200px;
      /deep/ .el-select__caret {
        color: #a3a3a3 !important;
      }
      /deep/ .el-input__suffix {
        top: 3px;
        right: 0px;
      }
      /deep/ .el-input__icon {
        line-height: inherit;
      }

      /deep/ .el-input__suffix-inner {
        display: inline-block;
      }
      /deep/ .el-input__inner {
        height: 27px;
        max-height: 30px !important;
        line-height: 30px;
        font-size: 12px !important;
        background: #fff !important;
        padding: 5px 10px !important;
        background-color: #fff !important;
        border-radius: 5px 0px 0px 5px;
      }
    }
  }

  .tab-header-tab {
    height: 26px;
    line-height: 26px;
    width: calc(100% - 10px);
    font-size: 13px;
    color: #333333;
    font-weight: bold;
    display: flex;
    // border-bottom: 1px solid #dddddd;
    position: relative;
    /deep/ .el-tabs__item {
      border: 1px solid #e4e7ed !important;
      border-bottom: none;
      border-top: none;
      // height: 100%;
      line-height: 26px;
      height: 26px;
      font-size: 12px;
      width: 175px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-left: 10px;
      background-color: #fff;
    }
    /deep/ .el-tabs--border-card {
      border: none !important;
      box-shadow: none !important;
    }
    /deep/.el-tabs--border-card > .el-tabs__header .el-tabs__item.is-active {
      background-color: #378ddd;
      color: #fff !important;
    }
    /deep/
      .el-tabs--top.el-tabs--border-card
      > .el-tabs__header
      .el-tabs__item:nth-child(2) {
      padding-left: 10px !important;
    }
    /deep/ #tab-2 {
      padding-left: 10px !important;
    }
    /deep/.el-tabs__nav {
      display: flex;
    }
    /deep/.el-tabs__nav-scroll {
      height: 26px;
    }
    /deep/ .el-tabs__nav-prev,
    /deep/ .el-tabs__nav-next {
      line-height: 26px;
    }
    /deep/.el-tabs__content {
      padding: 0;
    }
    .layoutbox {
      flex: 1;
      background-color: #fff;
      position: relative;
      /deep/.el-dialog__body {
        padding-left: 30px;
      }
    }
    /deep/.el-icon-close {
      font-size: 16px;
      display: flex;
      align-items: center;
    }
  }
}

.el-button {
  border-radius: 4px;
  font-size: 12px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #ffffff;
  padding: 8px 25px;
}
.el-button--cancel {
  background: #b0b0b0;
}
.el-button--sure {
  background: #4385f2;
}
.el-button--delete {
  border: 1px solid #d44051;
  color: #d44051;
}
/deep/ .el-dialog__body {
  padding: 20px !important;
  .dialog-body {
    display: flex;
  }
}

.el-input-number {
  width: 200px;
  /deep/ .el-input-number {
    width: 59px;
    height: 23px;
  }
  /deep/ .el-input {
    position: static !important;
  }
  /deep/ .el-input__inner {
    // width: 100px;
    height: 23px;
    background: #ffffff !important;
    border: 1px solid #dddddd;
    border-radius: 2px;
    padding: 0 !important;
    text-align: center;
    display: inline-block;
  }
}

.el-table {
  width: 100%;
  background: #ffffff;
  // border: 1px solid #DDDDDD;
  // box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.06);
  /deep/ .table_header {
    background: #e9eff3 !important;
  }
  /deep/ .el-table__cell {
    padding: 3px !important;
    font-size: 12px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #333333;
    background: #ffffff;
    border-bottom: none !important;
  }
  /deep/ .el-input__inner {
    background: transparent !important;
  }
  .disabled {
    color: #b2b2b2 !important;
  }
}
.image {
  width: 100%;
  margin: auto;
}
.args-add {
  cursor: pointer;
  padding: 0 5px;
  color: #0c7de6;
}
.args-delete {
  cursor: pointer;
  padding: 0 5px;
  color: #de5454;
}

.inputArea {
  position: relative;
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  // justify-content: space-between;
  /deep/.el-select {
    width: 566px;
    border-radius: 2px;
  }
  /deep/.el-input {
    width: 566px;
    height: 30px;
    background: #f4f4f4;
    border-radius: 2px;
  }
  /deep/.el-input__icon {
    line-height: 30px;
  }
  /deep/.el-input__inner {
    height: 30px;
    background: transparent !important;
  }
  .inputInfo {
    width: 100px;
  }
  /deep/ .el-select {
    background: transparent !important;
  }
  .inputInfo::before {
    content: "*";
    color: red;
    margin-right: 5px;
  }
}
.labelSelect {
  position: relative;
  width: 100%;
  min-height: 30px;
  height: auto;
  padding: 13px 0;
  display: flex;
  align-items: center;
  // justify-content: space-between;
  text-align: left;
  .inputInfo {
    width: 100px;
  }
  .inputInfo::before {
    content: "*";
    color: red;
    margin-right: 5px;
  }
  .el-tag {
    margin-right: 10px;
  }
  .button-new-tag {
    // margin-left: 10px;
    height: 32px;
    line-height: 30px;
    padding-top: 0;
    padding-bottom: 0;
  }
  .input-new-tag {
    width: 90px;
    // margin-left: 10px;
    vertical-align: bottom;
  }
  .el-form-item__content {
    max-height: calc(100% - 100px);
  }
}
.textAreaSelect {
  position: relative;
  width: 100%;
  min-height: 30px;
  padding: 13px 0;
  display: flex;
  align-items: center;
  // justify-content: space-between;
  .inputInfo {
    width: 100px;
  }
  .inputInfo::before {
    content: "\00a0";
    color: red;
    margin-right: 5px;
  }
  /deep/.el-textarea {
    width: 566px;
    .el-textarea__inner {
      background-color: #f4f4f4;
    }
    .el-input__count {
      background-color: #f4f4f4;
    }
  }
}
.item_class {
  font-size: 13px;
  font-weight: 400;
  color: #0c7de6;
  width: 100%;
  max-height: 75px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  // text-overflow: ellipsis;
  -webkit-line-clamp: 4; /*限制行数为4行*/
  line-clamp: 4; /*限制行数为4行*/
}
#preview-tab-body {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
