<template>
  <div>
    <div class="header" onselectstart="javascript:return false;">
      <!-- logo+选择菜单 -->
      <div class="h-left">
        <div class="text-logo">{{$t('messages.pcellGenerator')}}</div>
      </div>
      <!-- 用户&选项 -->
      <div class="h-right">
        <!-- 图标 -->
        <!-- <el-dropdown> -->
        <!-- <div class="old-version" @click="toOldVersion">{{ $t('messages.oldVersion') }}</div> -->
        <div class="headerChangeLang">
          <img alt="" v-if="isLanguageCN" class="language-icon" @click.stop="changeLang()" src="@/assets/icons/file/中文.svg" />
          <img alt="" v-else class="language-icon" @click.stop="changeLang()" src="@/assets/icons/file/英文.svg" />
        </div>
        <img alt="" src="@/assets/personalCenter/个人默认头像.png" />
        <span class="username">{{username}}</span>
        <el-dropdown>
          <span class="el-icon-arrow-down setting el-dropdown-link"></span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item>
              <span @click="toSelf">{{ $t('messages.userCenter') }}</span>
            </el-dropdown-item>
            <!-- <el-dropdown-item><span @click="changePsd">安全设置</span>
          </el-dropdown-item> -->
            <el-dropdown-item>
              <span style="display: inline-block; width: 100%; height: 100%" @click="dialogVisible = true">{{ $t('messages.quit') }}</span>
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>
    <div class="dialog">
      <!-- 点击退出登录弹出框 -->
      <el-dialog :title="$t('messages.tips')" :visible.sync="dialogVisible">
        <span>{{ $t('messages.reallyQuit') }}</span>
        <span slot="footer" class="dialog-footer">
          <el-button size="small" @click="dialogVisible = false">{{ $t('messages.cancel') }}</el-button>
          <el-button size="small" type="primary" @click="exit">{{ $t('messages.sure') }}</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import VueFileToolbarMenu from 'vue-file-toolbar-menu'
import bus from '@/components/common/bus'
import '@/assets/iconfont/iconfont.css'
import { logout_api } from '@/api/login/login.js'
import * as kdtreefast from '@/utils/kdtree-fast/kdtree_fast'
window.KDTreeFast = kdtreefast
export default {
  components: {
    VueFileToolbarMenu,
  },
  data() {
    return {
      username: localStorage.getItem('userName'),
      dialogVisible: false,
      createProject: false, // 新建项目弹窗
      createFile: false,
      uploadDialogVisible: false,
      projectName: '', //项目名称
      fileName: '',
      options: [
        {
          value: 1,
        },
        {
          value: 2,
        },
      ],
      value: '',
      clickComponent: false,
      isLanguageCN: true,
      history: false,
      historyArr: [],
      noHistory: [
        {
          html: `<span style="flex:1;line-height:30px;padding-left:25px;" class="historyItem">${this.$t('messages.browsingRecord')}</span>`,
        },
      ],
      //导入GDS
      importDialogVisible: false,
      transmissionQueue: [
        {
          name: '文件1名称',
          info: '进行中',
        },
        {
          name: '文件2名称',
          info: '进行中',
        },
        {
          name: '文件3名称',
          info: '进行中',
        },
        {
          name: '文件4名称',
          info: '等待中',
        },
        {
          name: '文件4名称',
          info: '等待中',
        },
      ],
      //另存为单元是否打开单元
      isOpenCell: false,
      showCoordinate: true,
      showCoordinateInstanceName: true,
      showCoordinateLabelName: true,
      showCoordinateKeyParameter: true,
      showCoordinatePinName: true,
      showCoordinatePortName: true,
      showCoordinateNetLable: true,
      showCoordinateOtherLabel: true,
      batchCreateCell: false,
      //siderbar是否激活画图功能
      isDraw: false,
      //导航栏切换
      cell_type: '0',
      //只读模式
      readOnly: false,
      //是否可以打开符号
      hasCapSymbolToOpen: false,
    }
  },
  mounted() {
    
  },
  beforeDestroy() {
    
  },
  methods: {
    // 进入团队中心
    toTeamInfo() {
      bus.$emit('jumpToOtherPage', true)
      this.$router.push('/user/teaminfo')
      this.$store.commit('updateOpenedTab', [])
    },
    //进入个人中心
    toSelf() {
      bus.$emit('jumpToOtherPage', true)
      this.$router.push('/user')
      this.$store.commit('updateOpenedTab', [])
    },
    // 修改密码
    changePsd() {
      bus.$emit('jumpToOtherPage', true)
      this.$router.push('/password')
    },
    // 退出登录
    async exit() {
      bus.$emit('jumpToOtherPage', true)
      logout_api().then(res => {
        localStorage.removeItem('userId');
        localStorage.removeItem('userName');
        localStorage.removeItem('user');
        localStorage.removeItem('apiKey');
        localStorage.removeItem('bucket_name');
        localStorage.removeItem('minio_name');
        localStorage.removeItem('qid');
        this.$router.push('/login')
      })
    },
    changeLang() {
      this.isLanguageCN = !this.isLanguageCN
      if (this.isLanguageCN) {
        this.changeLangCN()
      } else {
        this.changeLangEN()
      }
      this.noHistory = [
        {
          html: `<span style="flex:1;line-height:30px;padding-left:25px;" class="historyItem">${this.$t('messages.browsingRecord')}</span>`,
        },
      ]
    },
    changeLangCN() {
      window.lang = 'zh'
      this.$i18n.locale = window.lang
      this.$bus.$emit('changeLang', 'zh')
      localStorage.setItem('lang', 'zh')
    },
    changeLangEN() {
      window.lang = 'en'
      this.$i18n.locale = window.lang
      this.$bus.$emit('changeLang', 'en')
      localStorage.setItem('lang', 'en')
    },
  },
  watch: {
    
  },
  computed: {
  },
  created() {
    if (localStorage.getItem('lang')) {
      window.lang = localStorage.getItem('lang')
      this.$i18n.locale = window.lang
    }
    if (window.lang === 'zh') {
      this.isLanguageCN = true
    } else {
      this.isLanguageCN = false
    }
  },
}
</script>
<style lang="less" scoped>
.header {
  height: 30px !important;
  background-color: #378ddd;
  color: #fff;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  padding-right: 25px;
  // 图标
  img {
    width: 24px;
    height: 24px;
    margin-right: 10px;
    border-radius: 50%;
  }
  .h-left {
    height: 100%;
    display: flex;
    padding-left: 10px;
    .logo {
      width: 160px;
      height: 100px;
      margin: -35px;
    }
    .text-logo{
      font-size: 13px;
      position: relative;
      top: 6px;
    }
    .menuBox {
      margin-left: -20px;
    }
  }
}
// 菜单栏样式
/deep/ .bar {
  height: 100% !important;
}
/deep/ .bar-button {
  font-size: 12px !important;
}
/deep/.bar-menu-item {
  width: 170px;
  height: 16px;
  line-height: 16px;
  color: #333;
  position: relative;
}
/deep/.bar-menu-item:hover {
  color: #0c7de6;
}
/deep/.bar-menu-separator {
  margin: 2px 0 !important;
}
// 输入框
/deep/.inname .el-input {
  width: 100%;
  margin-bottom: 20px;
  > input {
    width: 80%;
    display: block;
    margin: 0 auto;
  }
  > span {
    right: 12%;
  }
}
.selectType {
  width: 80%;
  margin: 0 auto;
  /deep/ .el-select {
    width: 100%;
  }
}
/deep/ .el-dialog__body {
  padding: 30px 20px;
}
/deep/ .el-select-dropdown__item {
  background-color: red !important;
  width: 1000px !important;
}
/deep/.historyItem {
  line-height: 32px;
  padding-left: 5px;
  width: 170px;
  display: flex;
  align-items: center;
}

/deep/.historyIcon::before {
  font-size: 20px;
  padding-right: 5px;
}

/deep/.historyText {
  max-width: 135px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

/deep/.hoverItem {
  line-height: 30px;
  padding-left: 16px;
  position: absolute;
  top: 32px;
  left: 0;
  z-index: 10000;
  border: 1px solid #999999;
  background-color: #fff;
  width: 182px;
}
/deep/.bar-menu-item.disabled:hover {
  color: rgba(0, 0, 0, 0.3) !important;
}
//>箭头符号
/deep/.material-icons {
  font-size: 14px;
}
</style>
<style>
/* 全局定义顶部弹框 */
/* .el-dialog__wrapper {
  z-index: 10001 !important;
} */
.el-dialog {
  top: 50%;
  left: 50%;
  margin: 0 !important;
  transform: translate(-50%, -50%);
  border-radius: 4px;
}
.el-dialog__body {
  padding: 10px 40px;
}
.inname {
  padding-top: 10px;
}
.el-dropdown-menu {
  z-index: 10000 !important;
}
.el-dropdown {
  display: flex;
  align-items: center;
}
.h-right {
  display: flex;
  align-items: center;
}
.headerChangeLang {
  cursor: pointer;
}
.login-head-lang-line {
  margin: 0 14px;
}
.language-icon {
  width: 25px !important;
  height: 25px !important;
  font-weight: bold;
  vertical-align: top;
}
.username {
  font-size: 12px;
}
.setting {
  color: #fff !important;
  cursor: pointer;
  padding: 5px;
}
.el-radio__input .el-radio__inner {
  border: 1px solid #818181;
}
.el-radio__input.is-checked .el-radio__inner {
  background-color: #fff;
  border-color: #0c7de6;
}
.el-radio__inner::after {
  background-color: #0c7de6;
}
.el-radio__input.is-checked + .el-radio__label {
  color: #333333;
}
.deactivate {
  background-color: #aac6f5 !important;
  border-color: #aac6f5 !important;
}
.old-version {
  font-size: 12px;
  padding: 2px 4px;
  background: #ffffff;
  border-radius: 2px;
  color: #378ddd;
  margin-right: 12px;
  cursor: pointer;
}
</style>
